import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useLocation } from 'react-router-dom';

export const HOTJAR_ID = 3400411;

const useHotjar = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_VERSION === 'prod' && !hotjar.initialized()) {
      hotjar.initialize({
        id: HOTJAR_ID,
        sv: 6,
      });
    }
  }, []);

  useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.stateChange(location.pathname);
    }
  }, [location]);
};

export default useHotjar;
