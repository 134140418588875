import React, { useCallback, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';

import { useS3Bucket } from 'hooks/S3Bucket';
import { useWatchDepthTracking } from 'hooks/watchDepthTracking';

import { useGetGameInfoQuery } from 'store/api/gameService/recipe.api';

import { formatViewDate } from 'utils/date';
import { generateGameMaterials } from 'utils/generateMaterials';
import { isImgMedia } from 'utils/img';
import { findPathPages } from 'utils/pages';
import { triggerPlausibleEvent } from 'utils/plausible';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import ComingSoon from 'components/ComingSoon/ComingSoon';
import Copy from 'components/Copy/Copy';
import DownloadMaterials from 'components/DownloadMaterials/DownloadMaterials';
import GameDemo from 'components/GameDemo/GameDemo';
import GameId from 'components/GameId/GameId';
import Loader from 'components/Loader/Loader';
import Button from 'components/UI/Button/Button';
import Dialog from 'components/UI/Dialog/Dialog';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import Currency from 'components/pages/Game/Currency/Currency';
import Features from 'components/pages/Game/Features/Features';
import Type from 'components/pages/Game/Type/Type';
import NotFound from 'components/pages/NotFound/NotFound';

import { gamePath } from 'components/config/cloud';
import { materialsStructure } from 'components/config/games';
import { Flags, getLanguages } from 'components/config/languages';
import { PAGE_PATH } from 'components/config/pages';
import { plausibleEvents } from 'components/config/plausibleEvents';

import defaultBg from 'images/Game/default_bg.jpeg';
import { ReactComponent as DesktopIcon } from 'images/Icons/Desktop.svg';
import { ReactComponent as GameIcon } from 'images/Icons/Game.svg';
import { ReactComponent as MobileIcon } from 'images/Icons/Mobile.svg';

import styles from './Game.module.scss';

const Game = () => {
  const [openDemo, setOpenDemo] = useState(false);

  const { gameCode } = useParams();
  const { isLoading, data } = useGetGameInfoQuery({ gameCode });

  const { fetchData: fetchMaterials, loading: materialsLoading } = useS3Bucket({
    prefixes: `Games/${gameCode}/`,
    formatItems: generateGameMaterials,
  });

  const isImg = useMemo(() => isImgMedia(data?.media), [data]);
  const defaultText = useMemo(
    () =>
      data?.releaseDate && new Date(data.releaseDate) > new Date() ? (
        <ComingSoon />
      ) : (
        <span className={styles.empty}>&mdash;</span>
      ),
    [data],
  );

  const events = useMemo(() => {
    const createEvent = (name) => ({
      name,
      props: {
        page: `game-detail-${data?.provider}`,
      },
    });

    return {
      clickDownload: createEvent(plausibleEvents.clickDownload),
      playDemo: createEvent(plausibleEvents.playDemo),
    };
  }, [data?.provider]);

  const { startTrackingTime, stopTrackingTime } = useWatchDepthTracking(
    events.playDemo,
  );

  const handleOpenDemo = useCallback(() => {
    setOpenDemo(true);

    triggerPlausibleEvent(events.playDemo);

    startTrackingTime();
  }, [events, startTrackingTime]);

  const handleCloseDemo = useCallback(() => {
    setOpenDemo(false);
    stopTrackingTime();
  }, [stopTrackingTime]);

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <NotFound />;
  }

  return (
    <>
      <div
        className={styles.header}
        style={{
          backgroundImage: `url(${
            data.background ? data.background : defaultBg
          })`,
        }}
      >
        <div className={styles.content}>
          <div className={styles.headerGradient} />
          <div className={styles.headerContent}>
            <div className={styles.back}>
              <ArrowCircleLink
                style={styles.backLink}
                path={findPathPages(PAGE_PATH.ALL_GAMES).replace(
                  ':provider',
                  data.provider,
                )}
                title="Go to all games"
                direction="left"
              />
            </div>
            <div className={styles.descriptionWrapper}>
              <div className={styles.mainInfo}>
                {data.marker && <Type type={data.marker} />}
                {data.logo && data.media && <img src={data.logo} alt="logo" />}
                <div className={styles.title}>
                  <span>{data.name}</span>
                </div>
                <div className={styles.categoryWrapper}>
                  <span className={styles.category}>
                    <span>{data.type}</span>
                  </span>
                  {data.id.length > 0 && <GameId id={data.id} />}
                </div>
                {data.description && (
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                )}
                <div className={styles.actions}>
                  <DownloadMaterials
                    variant="Primary"
                    type="Game"
                    structure={materialsStructure}
                    downloadFileName={data.name}
                    folderPath={gamePath(data.gameCode)}
                    gamesData={[data]}
                    provider={data.provider}
                    trackEvent={events.clickDownload}
                    fetchMaterials={fetchMaterials}
                    loading={materialsLoading}
                  >
                    Download materials
                  </DownloadMaterials>
                </div>
              </div>
              <div className={styles.video}>
                <div>
                  {!data.media && data.logo && (
                    <img src={data.logo} alt="logo" className={styles.img} />
                  )}
                  {isImg && data.media && (
                    <img src={data.media} alt="media" className={styles.img} />
                  )}
                  {!isImg && data.media && (
                    <ReactPlayer
                      url={data.media}
                      playing={false}
                      controls={false}
                      className={styles.youtube}
                    />
                  )}
                  {data.demo && (
                    <Button
                      onClick={handleOpenDemo}
                      variant="iconGradient"
                      startIcon={<GameIcon />}
                    >
                      Play Demo
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.technicalInfo}>
        <div className={styles.content}>
          <div className={styles.sectionTitle}>Technical information</div>
          <div className={styles.gameInfo}>
            <div className={styles.column}>
              <div className={styles.row}>
                <span>Release date:</span>
                <span>
                  {data.releaseDate
                    ? formatViewDate(new Date(data.releaseDate))
                    : defaultText}
                </span>
              </div>
              <div className={styles.row}>
                <span>RTP, %:</span>
                <span>
                  {data.rtp.length ? `${data.rtp.join('%, ')}%` : defaultText}
                </span>
              </div>
              <div className={styles.row}>
                <span>RTP Options:</span>
                <span>
                  {data.rtpOptions.length
                    ? `${data.rtpOptions.join('%, ')}%`
                    : defaultText}
                </span>
              </div>
              <div className={styles.row}>
                <span>Volatility:</span>
                <span>{data.volatility ? data.volatility : defaultText}</span>
              </div>
              <div className={styles.row}>
                <span>Min Bet, €:</span>
                <span>{data.minBet ? `€ ${data.minBet}` : defaultText}</span>
              </div>
              <div className={styles.row}>
                <span>Max Bet, €:</span>
                <span>{data.maxBet ? `€ ${data.maxBet}` : defaultText}</span>
              </div>
              <div className={styles.row}>
                <span>Max Multiplier:</span>
                <span>
                  {data.maxMultiplier ? data.maxMultiplier : defaultText}
                </span>
              </div>
              <div className={styles.row}>
                <span>Max Win, €:</span>
                <span>{data.maxWin ? `€ ${data.maxWin}` : defaultText}</span>
              </div>
              <div className={styles.row}>
                <span>Hit Frequency:</span>
                <span>
                  {data.hitFrequency && data.hitFrequency.length > 0
                    ? `${data.hitFrequency.join('% — ')}%`
                    : defaultText}
                </span>
              </div>
              <div className={styles.row}>
                <span>Paylines:</span>
                <span>{data.paylines ? data.paylines : defaultText}</span>
              </div>
              <div className={styles.row}>
                <span>Game Format:</span>
                <span>{data.gameFormat ? data.gameFormat : defaultText}</span>
              </div>
              {data.absoluteName && (
                <div className={styles.absoluteName}>
                  <div>Absolute name:</div>
                  <Copy
                    copyText={data.absoluteName}
                    text=""
                    iconType="stroke"
                  />
                </div>
              )}
            </div>
            <div>
              <div className={styles.appWeight}>
                <div className={styles.desktop}>
                  <DesktopIcon />
                  <div className={styles.title}>Desktop Weight:</div>
                  <div>
                    {data.desktopWeight ? data.desktopWeight : defaultText}
                  </div>
                </div>
                <div className={styles.mobile}>
                  <MobileIcon />
                  <div className={styles.title}>Mobile Weight:</div>
                  <div>
                    {data.mobileWeight ? data.mobileWeight : defaultText}
                  </div>
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.languages}>
                  <div>Supported Languages:</div>
                  <div>
                    {data.languages.length > 0
                      ? Array.from(new Set(data.languages)).map((language) =>
                          getLanguages(language) &&
                          Flags[getLanguages(language)] ? (
                            <Tooltip
                              title={getLanguages(language)}
                              key={language}
                            >
                              {Flags[getLanguages(language)]({
                                width: 24,
                                height: 24,
                              })}
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={getLanguages(language)}
                              key={language}
                            >
                              <span>{language}</span>
                            </Tooltip>
                          ),
                        )
                      : defaultText}
                  </div>
                </div>
                {data.promoTools.length > 0 && (
                  <div className={styles.tags}>
                    <div>Promo Tools:</div>
                    <div className={styles.tag}>
                      {data.promoTools.map((value, index) => (
                        <span key={index}>{value}</span>
                      ))}
                    </div>
                  </div>
                )}
                {data.tags.length > 0 && (
                  <div className={styles.tags}>
                    <div>Tags:</div>
                    <div className={styles.tag}>
                      {data.tags.map((value, index) => (
                        <span key={index}>{value}</span>
                      ))}
                    </div>
                  </div>
                )}
                {data.features.length > 0 && (
                  <div className={styles.tags}>
                    <div>Game Features:</div>
                    <div className={styles.tag}>
                      {data.features.map((value, index) => (
                        <span key={index}>{value}</span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <Currency gameCode={gameCode} />
            </div>
          </div>
        </div>
      </div>
      {data.gameFeatures && data.gameFeatures.length > 0 && (
        <Features data={data.gameFeatures} title="Game Features" />
      )}
      {data.demo && (
        <Dialog
          handleClose={handleCloseDemo}
          open={openDemo}
          options={{ type: 'opacity', hideScroll: true }}
        >
          <GameDemo src={data.demo} />
        </Dialog>
      )}
    </>
  );
};

export default Game;
