import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import classNames from 'classnames';
import { useS3Bucket } from 'hooks/S3Bucket';

import {
  convertFolderPathToName,
  generatePromoMaterials,
} from 'utils/generateMaterials';
import { findPathPages } from 'utils/pages';
import { isRunning } from 'utils/promotions';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import DownloadMaterials from 'components/DownloadMaterials/DownloadMaterials';
import Type from 'components/pages/Game/Type/Type';
import NotFound from 'components/pages/NotFound/NotFound';
import GamesParticipating from 'components/pages/Promotions/Item/Content/GamesParticipating';
import TechnicalInformation from 'components/pages/Promotions/Item/Content/TechnicalInformation';
import TermsAndPrize from 'components/pages/Promotions/Item/Content/TermsAndPrize';
import Tags from 'components/pages/Promotions/Tags/Tags';

import { PAGE_PATH } from 'components/config/pages';
import { plausibleEvents } from 'components/config/plausibleEvents';
import { data, materialsStructure } from 'components/config/promotions';

import stylesGame from 'components/pages/Game/Game.module.scss';
import styles from 'components/pages/Promotions/Item/Item.module.scss';

const Item = () => {
  const [expand, setExpand] = useState('');
  const { pathname } = useLocation();
  const { provider, category, item } = useParams();

  const handleClickExpand = useCallback((type) => {
    setExpand((prevState) => (prevState !== type ? type : ''));
  }, []);

  const promoData = useMemo(
    () => data[provider].find((el) => el.title === item),
    [pathname],
  );

  if (!promoData) {
    return <NotFound />;
  }

  const { fetchData: fetchMaterials, loading: materialsLoading } = useS3Bucket({
    prefixes: `Promotions/${convertFolderPathToName(promoData.folderPath)}/materials/`,
    formatItems: generatePromoMaterials,
  });

  const formatCategory =
    category === 'comingSoon'
      ? 'Coming Soon'
      : category.charAt(0).toUpperCase() + category.slice(1);

  return (
    <>
      <div
        className={stylesGame.header}
        style={{
          backgroundImage: `url(${promoData.folderPath}background.jpg)`,
        }}
      >
        <div className={stylesGame.content}>
          <div className={stylesGame.headerGradient}></div>
          <div className={stylesGame.headerContent}>
            <div className={stylesGame.back}>
              <ArrowCircleLink
                style={stylesGame.backLink}
                path={findPathPages(PAGE_PATH.PROMOTIONS).replace(
                  ':provider',
                  provider,
                )}
                title="Go to all promotions"
                direction="left"
              />
            </div>
            <div className={stylesGame.descriptionWrapper}>
              <div className={stylesGame.mainInfo}>
                <Type type={formatCategory} />
                <div className={stylesGame.title}>
                  <span>{promoData.title}</span>
                </div>
                <Tags tags={promoData.tags} />
                <div className={styles.period}>
                  <span>Start Date:</span>
                  <span>{promoData.period[0]}</span>
                </div>
                <div className={styles.period}>
                  <span>End Date:</span>
                  <span>{promoData.period[1]}</span>
                </div>
                <div
                  className={stylesGame.description}
                  dangerouslySetInnerHTML={{ __html: promoData.description }}
                />
                <div className={stylesGame.actions}>
                  <DownloadMaterials
                    variant="Primary"
                    structure={materialsStructure}
                    downloadFileName={promoData.title}
                    folderPath={promoData.folderPath}
                    type="Promo"
                    provider={provider}
                    trackEvent={{
                      name: plausibleEvents.clickDownload,
                      props: {
                        page: `promotions-detail-${provider}`,
                      },
                    }}
                    fetchMaterials={fetchMaterials}
                    loading={materialsLoading}
                  >
                    Download materials
                  </DownloadMaterials>
                </div>
              </div>
              <div className={stylesGame.video}>
                <div>
                  <img
                    src={`${promoData.folderPath}main.jpg`}
                    alt={promoData.title}
                    className={stylesGame.img}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(stylesGame.content, stylesGame.technicalInfo)}>
        <div className={stylesGame.sectionTitle}>Technical information</div>
        <TechnicalInformation {...promoData} />
        {promoData.gamesParticipating && (
          <>
            <div
              className={classNames(stylesGame.sectionTitle, styles.section)}
            >
              Games participating
            </div>
            <GamesParticipating
              gamesParticipating={promoData.gamesParticipating}
            />
          </>
        )}
        <div
          className={classNames(styles.section, styles.sectionTitle, {
            [styles.termsAndPrize]:
              promoData.placeAndPrize && promoData.termsAndConditions,
          })}
        >
          <TermsAndPrize {...promoData} />
        </div>
        {promoData.phases && (
          <div className={styles.section}>
            <div className={stylesGame.sectionTitle}>Included campaigns</div>
            {promoData.phases.map((item, index) => (
              <div
                className={classNames(styles.phase, {
                  [styles.phaseOpen]: expand === item.title,
                })}
                key={index}
              >
                <div
                  className={classNames(styles.header, {
                    [styles.materials]: item.materials,
                  })}
                  onClick={() => handleClickExpand(item.title)}
                >
                  <div className={styles.img}>
                    <img src={`${item.folderPath}main.jpg`} alt="phase" />
                  </div>
                  <div>
                    <div>
                      <div className={styles.title}>
                        {item.title}
                        {isRunning(item.period) && (
                          <Type type="Running" className={styles.running} />
                        )}
                      </div>
                      <div className={styles.period}>
                        {item.period[0].split(' ')[0]}
                        {' - '}
                        {item.period[1].split(' ')[0]}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Tags tags={item.tags} />
                  </div>
                  {item.materials && (
                    <div>
                      <DownloadMaterials
                        variant="Base"
                        type="Promo"
                        materials={item.materials}
                        structure={materialsStructure}
                        downloadFileName={item.title}
                        folderPath={item.folderPath}
                      >
                        Download materials
                      </DownloadMaterials>
                    </div>
                  )}
                  <div className={styles.arrowWrapper}>
                    <div
                      className={classNames(styles.arrow, {
                        [styles.arrowUp]: expand === item.title,
                        [styles.arrowDown]: expand !== item.title,
                      })}
                    ></div>
                  </div>
                </div>
                {expand === item.title && (
                  <div className={styles.description}>
                    <TechnicalInformation {...item} />
                    {item.gamesParticipating && (
                      <div className={styles.phaseParticipating}>
                        <GamesParticipating
                          gamesParticipating={item.gamesParticipating}
                          title="Games participating"
                        />
                      </div>
                    )}
                    <div
                      className={classNames(styles.phaseTermsAndPrize, {
                        [styles.termsAndPrize]:
                          item.termsAndConditions && item.placeAndPrize,
                      })}
                    >
                      <TermsAndPrize {...item} />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <div>
          <div>Are you in?</div>
          <div>Please contact your Key Account Manager</div>
        </div>
      </div>
    </>
  );
};

export default Item;
